// Open modal after 5 seconds if hasn't been loaded on computer
setTimeout(function(){
	function getCookie(cname) {
	  var name = cname + "=";
	  var ca = document.cookie.split(';');
	  for(var i = 0; i < ca.length; i++) {
	    var c = ca[i];
	    while (c.charAt(0) == ' ') {
	      c = c.substring(1);
	    }
	    if (c.indexOf(name) == 0) {
	      return c.substring(name.length, c.length);
	    }
	  }
	  return "";
	}
	function checkCookie(cookieName) {
	  var seen = getCookie(cookieName);
	  if (seen == "true") {
	    console.log("modal already seen");
	  } else {
	   	element = document.getElementsByClassName("inner-signup-modal")[0]
		element.classList.remove("hidden");
		document.cookie = "modalSeen=true; expires=Thu, 10 Dec 2022 12:00:00 UTC";
	  }
	}
	checkCookie("modalSeen")
}, 5000);
